@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";
*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

html, body {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  background: #eef1ff;
  margin: 0;
  padding: 0;
  font-family: Montserrat;
  overflow-x: hidden;
}

#hero div {
  margin: 10px 0;
}

.viewProject {
  color: #fff;
  cursor: pointer;
  background-color: #000;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.viewProject:after {
  content: "";
  z-index: -1;
  background-color: #1d1d1d;
  transition: left .3s;
  position: absolute;
  inset: 0 0 0 100%;
}

.viewProject:hover:after {
  left: 0;
}

.viewProject:hover {
  color: #fff;
}

html {
  scroll-behavior: smooth;
}

div#main {
  width: 100vw;
  flex-direction: column;
  display: flex;
}

section {
  width: 100vw;
  position: relative;
}

section.min-height {
  min-height: 100vh;
}

section.padding {
  padding: 5rem 0;
}

section img.background {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  pointer-events: none;
  width: 100vmax;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

h1 {
  margin: 0;
  font-family: Montserrat, serif;
  font-size: 7rem;
  font-weight: 400;
  line-height: .8;
}

h2 {
  margin: 0;
  font-family: Montserrat, serif;
  font-size: 5rem;
  font-weight: 400;
  line-height: .8;
}

h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
}

p {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
}

p.large {
  font-size: 24px;
}

p.small {
  font-size: 15px;
}

hr {
  margin: 2.5rem;
}

li {
  line-height: 1.7;
}

li::marker {
  color: #4e567e;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #4e567e;
}

.container {
  grid-gap: 20px;
  grid-template-columns: 225px 225px 225px 225px 225px 225px;
  margin: 20px auto;
  display: grid;
}

.container .box {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 16px;
  display: flex;
  box-shadow: 0 34px 35px #00000040, 0 -12px 30px #0000001f, 0 4px 6px #0000001f, 0 12px 13px #0000002b, 0 -3px 5px #00000017;
}

img.socialIcon {
  height: 30px;
  width: 30px;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

nav ul {
  list-style: none;
  display: flex;
}

nav ul li {
  margin: 0 15px;
}

section {
  text-align: center;
  padding: 50px;
}

section h2 {
  font-size: 40px;
}

#about {
  padding: 20px;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 48px;
}

.skills-grid {
  grid-template-columns: 1fr 1fr;
  place-items: center;
  gap: 1rem;
  display: grid;
}

.skills-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.skills-list li {
  border-bottom: 1px solid #ddd;
  padding: .5rem 0;
}

@media (max-width: 768px) {
  .skills-grid {
    grid-template-columns: 1fr;
  }
}

.project-showcase {
  margin: 20px;
  padding: 10px;
  display: flex;
}

.project-details {
  text-align: left;
  background-color: #fff;
  border-radius: 10px;
  flex: 1;
  padding: 10px;
}

.project-details ul {
  flex-wrap: wrap;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.project-details li {
  margin: 0 6px;
}

.project-details h3 {
  color: #333;
  margin-top: 1rem;
  font-size: 1.2em;
  font-weight: 600;
}

.project-slideshow {
  flex: 1;
  padding: 10px;
}

.project-footer {
  color: #fff;
  align-self: center;
  margin-top: auto;
}

.project-footer a {
  color: #fff;
  background-color: #000;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px 15px;
  text-decoration: none;
  display: inline-block;
}

/*# sourceMappingURL=index.99aef8dc.css.map */
